import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

import App from "./App";
import store from "./common/store";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/main.css";
import "./common/i18n";
import customTheme from "./assets/styles/customTheme";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={customTheme}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.querySelector("#root")
);

serviceWorker.unregister();
