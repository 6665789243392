import theme from "./theme";

export default {
  components: {
    Button: {
      colorPrimaryHover: theme.color.button.primaryHover,
      colorPrimary: theme.color.button.primary,
    },
  },
};
