import { Row, Col } from "antd";
import React from "react";

interface Props {
  title?: string;
}

const FormHeader: React.FC<Props> = ({ title = "" }) => {
  return (
    <Row justify="center" className="mb-5">
      <Col className="flex items-center justify-center">
        <span className="m-0 text-2xl font-bold leading-7">{title}</span>
      </Col>
    </Row>
  );
};

export default FormHeader;
