import _lodashGet from "lodash/get";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
  AxiosError,
} from "axios";
import { getUserToken } from "accelerate-core-ui/dist/utils";
import { ApiErrorModel } from "models/apiErrorModel";

export default class HttpClient {
  private readonly baseUrl: string;

  constructor(url: string) {
    this.baseUrl = url;
  }

  private transformResponse(
    input: string
  ): AxiosResponseTransformer | AxiosResponseTransformer[] {
    return JSON.parse(input);
  }

  private handleRequestError = (error: unknown): ApiErrorModel => {
    if (
      error instanceof AxiosError &&
      error.response &&
      _lodashGet(error.response, "data.data")
    ) {
      return error.response.data.data;
    }
    return {
      errorCode: "ErrorUnknown",
      errorMessage: "Unknown error occurred",
      type: 0,
    };
  };

  private client(header = {}): AxiosInstance {
    // cancelToken and source declaration
    const cancelTokenSource = axios.CancelToken.source();

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUserToken()}`,
      ...header,
    };

    // axios client config
    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      cancelToken: cancelTokenSource.token,
      headers,
    };

    // axios client response transformer
    config.transformResponse = [
      (data) => {
        return data && typeof data === "string"
          ? this.transformResponse(data)
          : data;
      },
    ];

    // create axios client
    return axios.create(config);
  }

  /**
   *
   * @param url
   * @returns
   */
  public get(url: string): Promise<AxiosResponse> {
    return this.client().get(url);
  }

  /**
   *
   * @param url
   * @param payload
   * @returns
   */
  public async post<T>(
    url: string,
    payload: T,
    headers = {}
  ): Promise<AxiosResponse> {
    try {
      const response = await this.client(headers).post(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }

  public async patch<T>(url: string, payload: T): Promise<AxiosResponse> {
    try {
      const response = await this.client().patch(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }

  public async put<T>(url: string, payload: T): Promise<AxiosResponse> {
    try {
      const response = await this.client().put(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }

  public delete(url: string): Promise<AxiosResponse> {
    return this.client().delete(url);
  }
}
