import React from "react";
import { Layout } from "antd";
import { useSelector } from "common/store";

import Logo from "./Logo";
import AvatarMenu from "./AvatarMenu";

const { Header: AntdHeader } = Layout;

const Header: React.FC = () => {
  const token = useSelector((state) => state.auth.token);

  return token ? (
    <AntdHeader className="flex justify-between items-center w-full bg-transparent border-none">
      <Logo />
      <AvatarMenu />
    </AntdHeader>
  ) : (
    <Logo />
  );
};

export default Header;
