import React from "react";
import ContainerLayout from "components/Layout/ContainerLayout";

const Organization: React.FC = () => {
  return (
    <ContainerLayout>
      <h1> Welcome to Admin Dashboard </h1>
    </ContainerLayout>
  );
};

export default Organization;
