import { MainLayout, ViewLayout } from "components/Layout";
import React from "react";

import SideMenu from "../SideMenu";

const ContainerLayout: React.FC = ({ children }) => {
  return (
    <MainLayout>
      <ViewLayout menuElement={<SideMenu />}>
        <div className="w-full h-full flex items-center justify-center">
          {children}
        </div>
      </ViewLayout>
    </MainLayout>
  );
};

export default ContainerLayout;
