import { identityClient } from "common/client/identityClient";
import { ApiErrorModel } from "models/apiErrorModel";

import { LoginUser } from "../models/authModel";
import { createAppThunk } from "../common/appThunk";

const _PREFIX = "auth";

export const userLogin = createAppThunk(
  `${_PREFIX}/login`,
  async (body: LoginUser) => {
    try {
      const { data: response } = await identityClient.post(
        "identityusers/login",
        body
      );
      return response.data;
    } catch (error) {
      if ((error as ApiErrorModel).errorCode) {
        throw (error as ApiErrorModel).errorCode;
      }
      throw error;
    }
  }
);
