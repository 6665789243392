import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { getUserToken, error } from "accelerate-core-ui/dist/utils";

import { userLogin } from "../services/authService";

export interface AuthState {
  token?: string | null;
  error?: string;
  loading: boolean;
}

function getAuthInfo(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(userLogin.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.token = action.payload;
      localStorage.setItem("userToken", action.payload);
    })
    .addCase(userLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.error);
    });
}

const initialState: AuthState = {
  token: getUserToken(),
  error: undefined,
  loading: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutSuccess(state) {
      state.token = undefined;
      state.error = undefined;
      localStorage.removeItem("userToken");
    },
  },
  extraReducers: (builder) => {
    getAuthInfo(builder);
  },
});

export const { logoutSuccess } = slice.actions;
export default slice.reducer;
