import React from "react";

const Logo: React.FC = () => {
  return (
    <div className="p-6 flex items-center">
      <div className="text-white min-w-28 pl-5 font-black">Admin Dashboard</div>
    </div>
  );
};

export default Logo;
