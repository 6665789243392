import { FormContainer } from "components/Layout";

const PermissionDenied = () => {
  return (
    <FormContainer>
      <h2>Permission denied!</h2>
    </FormContainer>
  );
};

export default PermissionDenied;
