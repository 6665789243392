import React from "react";
import { Avatar, Dropdown, MenuProps } from "antd";
import { UserOutlined, LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "common/store";
import { logoutSuccess } from "slices/authSlice";

const AvatarMenu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuClick = (event: { key: string }) => {
    if (event.key === "logout") {
      dispatch(logoutSuccess());
      navigate("/login");
    }
  };

  const avatarMenuItems: MenuProps["items"] = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: "Profile",
    },
    {
      key: "changePassword",
      icon: <LockOutlined />,
      label: "Change Password",
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      onClick: handleMenuClick,
    },
  ];

  return (
    <Dropdown menu={{ items: avatarMenuItems }}>
      <Avatar
        size={32}
        icon={<UserOutlined />}
        className="ml-2 cursor-pointer"
      />
    </Dropdown>
  );
};

export default AvatarMenu;
