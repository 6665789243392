import React, { FormEventHandler } from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { emailRegEx } from "common/constants";

interface Props {
  onFinish: (value: { email: string }) => void;
  onSubmitCapture?: FormEventHandler<HTMLFormElement>;
}

const InvitationForm: React.FC<Props> = ({ onFinish, onSubmitCapture }) => {
  const { t } = useTranslation();

  return (
    <Form
      name="invitation"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      className="w-full"
      layout="vertical"
      onSubmitCapture={onSubmitCapture}
    >
      <Form.Item
        name="email"
        label={t("Email")}
        className="w-full"
        rules={[
          { required: true, message: "Please input your email!" },
          {
            pattern: emailRegEx,
            message: "Invalid email format",
          },
        ]}
        validateTrigger="onSubmit"
      >
        <Input placeholder={t("Email")} />
      </Form.Item>

      <Form.Item className="flex justify-between">
        <Button type="primary" htmlType="submit" className="mr-6">
          {t("SendInvitation")}
        </Button>
        <Button type="default" className="min-w-[150px]">
          {t("cancel")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InvitationForm;
