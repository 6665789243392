import React, { useCallback, useEffect, useMemo } from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "common/store";
import { userLogin } from "services/authService";
import FormHeader from "components/FormHeader";
import { FormContainer } from "components/Layout";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const token = useSelector((state) => state.auth.token);

  const onFinish = useCallback(
    (values: { email: string; password: string }) => {
      dispatch(
        userLogin({ username: values.email, password: values.password })
      );
    },
    []
  );

  useEffect(() => {
    if (token && !error && !loading) {
      navigate("/organizations");
    }
  }, [token]);

  const errorCode = useMemo(() => {
    if (error === "IncorrectPassword" || error === "NotFoundUserName") {
      return "ApiError_InvalidCredentials";
    }
    return error;
  }, [error]);

  return (
    <FormContainer className="absolute top-0 left-0 w-full">
      <FormHeader title="Admin Login" />
      <Form
        name="login"
        onFinish={onFinish}
        initialValues={{ remember: true }}
        className="w-full"
        layout="vertical"
      >
        <Form.Item
          name="email"
          label={t("Email")}
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email address!" },
          ]}
          className="w-full"
        >
          <Input placeholder="Your work email is your username" />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("Password")}
          rules={[{ required: true, message: "Please input your password!" }]}
          className="w-full"
        >
          <Input.Password placeholder="Create a password" />
        </Form.Item>

        <Form.Item className="w-full py-2">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="w-full"
          >
            {t("Login")}
          </Button>
        </Form.Item>

        {errorCode && <p className="text-red-500">{t(errorCode)}</p>}
      </Form>
    </FormContainer>
  );
};

export default Login;
