import { ContainerLayout, FormContainer } from "components/Layout";

const PermissionDenied = () => {
  return (
    <ContainerLayout>
      <FormContainer>
        <h2>Page Not Found</h2>
      </FormContainer>
    </ContainerLayout>
  );
};

export default PermissionDenied;
