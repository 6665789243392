import React from "react";
import { Alert, message } from "antd";
import { FormContainer } from "components/Layout";
import FormHeader from "components/FormHeader";
import ContainerLayout from "components/Layout/ContainerLayout";
import { inviteTenant } from "services/invitationService";
import Loader from "components/Loading/Loader";
import useFetch from "accelerate-core-ui/dist/hooks/useFetch";
import { ApiErrorModel } from "models/apiErrorModel";

import InvitationForm from "./InvitationForm";

const Invitation: React.FC = () => {
  const { isLoading, error, execute, clearError } = useFetch<
    string,
    void,
    ApiErrorModel
  >({
    fn: async (email: string) => {
      await inviteTenant({ email });
      message.success("Invitation email sent successfully");
    },
  });

  const onFinish = async (value: { email: string }) => {
    execute(value.email);
  };

  return (
    <ContainerLayout>
      {isLoading && <Loader />}
      <FormContainer>
        <FormHeader title="Invite Org Admin" />
        {error && <Alert message={error.errorMessage} type="error" />}
        <InvitationForm
          onFinish={onFinish}
          onSubmitCapture={() => clearError()}
        />
      </FormContainer>
    </ContainerLayout>
  );
};

export default Invitation;
