import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

interface FormContainerProps {
  className?: string;
}

const FormContainer: React.FC<FormContainerProps> = ({
  children,
  className,
}) => {
  return (
    <Content
      className={`flex justify-center items-center h-screen ${className}`}
    >
      <div className="p-8 flex-col justify-center min-w-80 items-center gap-8 bg-white rounded-lg shadow-lg border border-purple-950">
        {children}
      </div>
    </Content>
  );
};

export default FormContainer;
