import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  UserAddOutlined,
  UnorderedListOutlined,
  PieChartOutlined,
  BarChartOutlined,
} from "@ant-design/icons";

const OrgMenu: React.FC = () => {
  const menuItems = [
    {
      key: "org",
      label: "Organization Page",
      children: [
        {
          key: "invite",
          icon: <UserAddOutlined />,
          label: <Link to="/organizations/invite">Invite Org Admin</Link>,
        },
        {
          key: "list",
          icon: <UnorderedListOutlined />,
          label: <Link to="/organizations/list">Org List</Link>,
        },
      ],
    },
    {
      key: "chart",
      label: "Chart Page",
      children: [
        {
          key: "test1",
          icon: <PieChartOutlined />,
          label: <Link to="/chart/test">Chart test 1</Link>,
        },
        {
          key: "test2",
          icon: <BarChartOutlined />,
          label: <Link to="/chart/test">Chart test 2</Link>,
        },
      ],
    },
  ];

  return (
    <div className="h-full flex flex-col bg-transparent p-4">
      <div className="text-xl font-bold mb-4">Side Menu</div>
      <Menu
        mode="inline"
        className="flex-grow bg-white shadow-lg border-0"
        items={menuItems}
      />
    </div>
  );
};

export default OrgMenu;
