import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserToken } from "accelerate-core-ui/dist/utils";

interface ProtectedRouteProps {
  roleRequired?: "admin" | "user";
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roleRequired }) => {
  const isAuth = !!getUserToken();

  if (roleRequired && (!isAuth || roleRequired !== "admin")) {
    return isAuth ? <Outlet /> : <Navigate to="/denied" />;
  } else {
    return isAuth ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
