import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "containers/Authentication/Login";
import Header from "components/Header";
import OrganizationInvitation from "containers/Organization/Invitation";
import Organization from "containers/Organization";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import PermissionDenied from "./PermissionDenied";
import PageNotFound from "./PageNotFound";

const Router: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="organizations" />} />
          <Route path="organizations">
            <Route path="" element={<Organization />} />
            <Route path="invite" element={<OrganizationInvitation />} />
          </Route>
        </Route>
        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
