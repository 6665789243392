import { Layout, Card } from "antd";
import React, { ReactNode } from "react";

const { Sider, Content } = Layout;

interface Props {
  menuElement: ReactNode;
}

const ViewLayout: React.FC<Props> = ({ children, menuElement }) => {
  return (
    <Layout className="w-full h-screen px-2">
      <Sider className="py-2" theme="light" width="20%">
        {menuElement}
      </Sider>
      <Content className="py-2 pr-2">
        <Card
          className="w-full h-full"
          classNames={{ body: "w-full h-full" }}
          bordered={false}
        >
          {children}
        </Card>
      </Content>
    </Layout>
  );
};

export default ViewLayout;
