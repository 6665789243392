import { Navigate, Outlet } from "react-router-dom";
import { getUserToken } from "accelerate-core-ui/dist/utils";

const PublicRoutes = () => {
  const auth = !!getUserToken();

  return auth ? <Navigate to="/organizations" /> : <Outlet />;
};

export default PublicRoutes;
