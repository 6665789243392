import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const MainLayout: React.FC = ({ children }) => {
  return (
    <Layout className="flex flex-col min-h-screen bg-transparent">
      <Content className="flex-grow">{children}</Content>
    </Layout>
  );
};

export default MainLayout;
