const figmaColor = {
  purple900: "#7A27EF", // copy name from figma
};

const color = {
  sky: "#06BDF9",
  footer: {
    title: "#B57EFF",
  },
  button: {
    primary: figmaColor.purple900,
    primaryHover: "#C4B5FD",
  },
  menu: {
    items: {
      hoverBg: figmaColor.purple900,
      selectedColor: figmaColor.purple900,
      hoverColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    icon: {
      fill: figmaColor.purple900,
    },
  },
  icon: {
    default: "#FFFFFF",
  },
};

const size = {
  expandableIndent: 16,
  tableBottom: 16,
};

const theme = {
  color,
  size,
  font: {
    base: "Inter",
  },
};

export default theme;
